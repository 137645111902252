import { useMemo } from 'react'

import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import { BalanceParameters, BalanceResponse } from 'types/api/balance'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

const createBalanceParameters = (
  currencyPair: CurrencyPair,
  equivalentCurrency: Currency,
): BalanceParameters => {
  const currencySymbols = currencyPair
    .map((currency) => currency?.Symbol)
    .flat()

  const equivalentCurrencySymbol = equivalentCurrency?.Symbol

  return {
    name: 'Balance',
    Currencies: currencySymbols,
    EquivalentCurrency: equivalentCurrencySymbol,
  }
}

export const useBalance = (
  currencyPair: CurrencyPair,
  equivalentCurrency?: Currency,
): BalanceResponse[] | null => {
  const socket = useSocket()

  const balanceParameters = useMemo(
    () => createBalanceParameters(currencyPair, equivalentCurrency),
    [currencyPair, equivalentCurrency],
  )

  return useSubscription<BalanceResponse>(socket, balanceParameters)
}
