import { atomFamily, selectorFamily } from 'recoil'

import { OrderResponseData } from 'types/api/order-response'

export const orderResponses = atomFamily<Partial<OrderResponseData>, string>({
  key: 'OrderResponses',
  default: (param) => ({ ClOrdID: param }),
})

export const orderReponseSelector = selectorFamily({
  key: 'OrderResponseSelector',
  get:
    (orderId: string) =>
    ({ get }) => {
      return get(orderResponses(orderId))
    },
  set:
    (orderId) =>
    ({ set }, newValue) => {
      set(orderResponses(orderId), newValue)
    },
})
