import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'
import { StreamingTileSubscriptionParams } from 'types/streaming-tile-subscription-params'

import identify from './identify'

const getMonitorParams = {
  streamingTile: (
    sliver: StreamingTileLocalSliver,
  ): StreamingTileSubscriptionParams => {
    const { currencyPair, amount, normalSize, sizeBuckets } = sliver ?? {}

    if (!currencyPair) return null

    const currencyPairId = identify.currencyPair(currencyPair)

    const topBuckets = amount ? [amount] : normalSize ? [normalSize] : ['1']

    const topMonId = JSON.stringify([currencyPairId, topBuckets])
    const volMonId = JSON.stringify([currencyPairId, sizeBuckets])

    return {
      top: {
        monId: topMonId,
        params: {
          name: 'MarketDataSnapshot',
          Symbol: currencyPairId,
          SizeBuckets: topBuckets,
        },
      },
      vol: {
        monId: volMonId,
        params: {
          name: 'MarketDataSnapshot',
          Symbol: currencyPairId,
          SizeBuckets: sizeBuckets,
        },
      },
    }
  },
}

export default getMonitorParams
