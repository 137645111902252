import {
  NewOrderSingleMessage,
  OrderData,
  OrderParameters,
} from 'types/api/new-order-single'
import { QuoteResponseData } from 'types/api/quote-response'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

import { createReqId } from './utils'

export const createMarketOrderMessage = (
  pair: CurrencyPair,
  side: 'Buy' | 'Sell',
  qty: string,
  currency: Currency,
  params: OrderParameters = {},
): NewOrderSingleMessage => {
  const ClOrdID = crypto.randomUUID()
  const reqid = createReqId()
  const [base, quote] = pair

  const orderData: OrderData = {
    ClOrdID,
    Side: side,
    Symbol: `${base.Symbol}-${quote.Symbol}`,
    Currency: currency.Symbol,
    OrderQty: qty,
    TimeInForce: 'FillAndKill',
    OrdType: 'Market',
    Parameters: params,
  }

  return {
    reqid,
    type: 'NewOrderSingle',
    data: [orderData],
  }
}

export const createLimitOrderMessage = (
  pair: CurrencyPair,
  side: 'Buy' | 'Sell',
  qty: string,
  currency: Currency,
  price: string,
  params: OrderParameters = {},
): NewOrderSingleMessage => {
  const ClOrdID = crypto.randomUUID()
  const reqid = createReqId()
  const [base, quote] = pair

  const orderData: OrderData = {
    ClOrdID,
    Side: side,
    Symbol: `${base.Symbol}-${quote.Symbol}`,
    OrderQty: qty,
    Currency: currency.Symbol,
    TimeInForce: 'GoodTillCancel',
    OrdType: 'Limit',
    Parameters: params,
    Price: price,
    Strategy: 'Limit',
  }

  return {
    reqid,
    type: 'NewOrderSingle',
    data: [orderData],
  }
}

export const createRFQOrderMessage = (
  quoteResponseData: QuoteResponseData,
  side: 'Buy' | 'Sell',
): NewOrderSingleMessage => {
  const ClOrdID = crypto.randomUUID()
  const reqid = createReqId()
  const { OrderQty, RFQID, Currency, Symbol, QuoteID } = quoteResponseData

  const orderData: OrderData = {
    ClOrdID,
    Side: side,
    Symbol,
    OrderQty,
    Currency,
    TimeInForce: 'GoodTillCancel',
    OrdType: 'RFQ',
    QuoteID,
    RFQID,
  }

  return {
    reqid,
    type: 'NewOrderSingle',
    data: [orderData],
  }
}

export const createStopLimitOrderMessage = (
  pair: CurrencyPair,
  side: 'Buy' | 'Sell',
  qty: string,
  currency: Currency,
  price: string,
  params: OrderParameters = {},
): NewOrderSingleMessage => {
  const ClOrdID = crypto.randomUUID()
  const reqid = createReqId()
  const [base, quote] = pair

  const orderData: OrderData = {
    ClOrdID,
    Side: side,
    Symbol: `${base.Symbol}-${quote.Symbol}`,
    OrderQty: qty,
    Currency: currency.Symbol,
    Price: price,
    TimeInForce: 'GoodTillCancel',
    OrdType: 'Limit',
    Parameters: params,
    Strategy: 'StopLimit',
  }

  return {
    reqid,
    type: 'NewOrderSingle',
    data: [orderData],
  }
}

export const createSteadyPaceOrderMessage = (
  pair: CurrencyPair,
  side: 'Buy' | 'Sell',
  qty: string,
  currency: Currency,
  params: OrderParameters = {},
): NewOrderSingleMessage => {
  const ClOrdID = crypto.randomUUID()
  const reqid = createReqId()
  const [base, quote] = pair

  const orderData: OrderData = {
    ClOrdID,
    Side: side,
    Symbol: `${base.Symbol}-${quote.Symbol}`,
    OrderQty: qty,
    Currency: currency.Symbol,
    TimeInForce: 'FillOrKill',
    OrdType: 'Market',
    Parameters: params,
    Strategy: 'SteadyPace',
  }

  return {
    reqid,
    type: 'NewOrderSingle',
    data: [orderData],
  }
}
