import {
  atom,
  RecoilState,
  RecoilValueReadOnly,
  selector,
  selectorFamily,
} from 'recoil'

import { findRung, isNil } from 'lib/utils'
import getMonitorParams from 'lib/utils/getMonitorParams'
import identify from 'lib/utils/identify'
import { getDemoStreamingTiles } from 'lib/utils/simulation'
import store from 'store' // TBD cyclic import, not really great, toolset is coping, but a bad pattern
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { Group, GroupId, GroupPosition } from 'types/group'
import { MonitorParams } from 'types/monitor-params'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'
import { StreamingTileSubscriptionParams } from 'types/streaming-tile-subscription-params'

type TopMdsSelectedRungParam = {
  groupPosition: GroupPosition
  topMonId: string
  volMonId: string
}

export type StreamingTilesSlice = {
  selectedGroupId: RecoilState<GroupId>
  groups: RecoilState<Record<GroupId, Group>>
  groupIds: RecoilValueReadOnly<string[]>
  groupById: (groupId: GroupId) => RecoilState<Group>
  sliverByPosition: (
    groupPosition: GroupPosition,
  ) => RecoilState<StreamingTileLocalSliver>
  monitorParamsByPosition: (
    groupPosition: GroupPosition,
  ) => RecoilValueReadOnly<StreamingTileSubscriptionParams>
  streamingTilesMounted: RecoilValueReadOnly<StreamingTileLocalSliver[]>
  distinctCurrencyPairIds: RecoilValueReadOnly<string[]>
  monitorParams: RecoilValueReadOnly<MonitorParams[]>
  topMdsSelectedRung: (
    TopAmountVolumeRungParam,
  ) => RecoilValueReadOnly<MarketDataSnapshot>
}

const DEMO_INITIAL_NUM_PAIRS = 6

const selectedGroupId = atom<GroupId>({
  key: 'streamingTiles.selectedGroupId',
  default: 'Alpha',
})

const groups = atom<Record<GroupId, StreamingTileLocalSliver[]>>({
  key: 'streamingTiles.groups',
  default: {
    Alpha: getDemoStreamingTiles(DEMO_INITIAL_NUM_PAIRS),
    Beta: [],
    Gamma: [],
    Delta: [],
  },
})

const groupIds = selector({
  key: 'GroupIds',
  get: ({ get }) => Object.keys(get(groups)),
})

const groupById = selectorFamily<Group, GroupId>({
  key: 'streamingTiles.groupById',
  get:
    (groupId) =>
    ({ get }) => {
      const grps = get(groups)

      if (!groupId || !grps) return []

      return grps[groupId] ?? []
    },
  set:
    (groupId) =>
    ({ get, set }, nextVal) => {
      const prevGroups = get(groups)
      const nextGroups = {
        ...prevGroups,
        [groupId]: nextVal,
      }
      set(groups, nextGroups)
    },
})

const sliverByPosition = selectorFamily<
  StreamingTileLocalSliver,
  GroupPosition
>({
  key: 'streamingTiles.sliverByPos',
  get:
    ({ groupId, position }) =>
    ({ get }) =>
      get(groupById(groupId))[position],
  set:
    ({ groupId, position }) =>
    ({ get, set }, nextSliver: StreamingTileLocalSliver) => {
      const grp = get(groupById(groupId))
      const nextGrp = grp.map((prevSliver, i) =>
        position === i ? nextSliver : prevSliver,
      )
      set(groupById(groupId), nextGrp)
    },
})

const monitorParamsByPosition = selectorFamily<
  StreamingTileSubscriptionParams,
  GroupPosition
>({
  key: 'streamingTiles.monParamsByPos',
  get:
    (groupPosition) =>
    ({ get }) => {
      const sliver = get(sliverByPosition(groupPosition))

      return sliver ? getMonitorParams.streamingTile(sliver) : null
    },
})

const streamingTilesMounted = selector<StreamingTileLocalSliver[]>({
  key: 'streamingTiles.streamingTilesMounted',
  get: ({ get }) => {
    const displayedGroupId = get(selectedGroupId)
    const displayedGroup = get(groupById(displayedGroupId))

    return displayedGroup
  },
})

const monitorParams = selector<MonitorParams[]>({
  key: 'streamingTiles.monitorParams',
  get: ({ get }) => {
    const mounted = get(streamingTilesMounted)

    return mounted
      .map(getMonitorParams.streamingTile)
      .map((params) => Object.values(params))
      .flat()
  },
})

const distinctCurrencyPairIds = selector<string[]>({
  key: 'streamingTiles.distinctCurrencyPairIds',
  get: ({ get }) => {
    const mounted = get(streamingTilesMounted)

    return Array.from(
      new Set(
        mounted.map((sliver) => sliver.currencyPair).map(identify.currencyPair),
      ).keys(),
    )
  },
})

const topMdsSelectedRung = selectorFamily<
  MarketDataSnapshot,
  TopMdsSelectedRungParam
>({
  key: 'streamingTiles.topMdsSelectedRung',
  get:
    ({ groupPosition, topMonId, volMonId }) =>
    ({ get }) => {
      const { amount } = get(sliverByPosition(groupPosition)) ?? { amount: '' }
      const topMarketDataSnapshot = get(store.marketDataSnapshots(topMonId))
      const volMarketDataSnapshot = get(store.marketDataSnapshots(volMonId))

      if (isNil(amount) || !volMarketDataSnapshot) {
        return topMarketDataSnapshot
      }

      const { Bids: volBids, Offers: volOffers } = volMarketDataSnapshot

      const Bids = [findRung(amount, volBids)]
      const Offers = [findRung(amount, volOffers)]

      return { ...topMarketDataSnapshot, Bids, Offers } as MarketDataSnapshot
    },
})

export const streamingTiles: StreamingTilesSlice = {
  selectedGroupId,
  groups,
  groupIds,
  groupById,
  sliverByPosition,
  monitorParamsByPosition,
  streamingTilesMounted,
  distinctCurrencyPairIds,
  monitorParams,
  topMdsSelectedRung,
}
