import { atomFamily, selectorFamily } from 'recoil'

import { SecurityCustomerResponse } from 'types/api/security'

const responseBySymbol = atomFamily<SecurityCustomerResponse, string>({
  key: 'customerSecurities',
  default: null,
})

const normalSizeBySymbol = selectorFamily<string, string>({
  key: 'customerSecurities.normalSizeBySymbol',
  get:
    (symbol: string) =>
    ({ get }) =>
      get(responseBySymbol(symbol))?.NormalSize,
})

const canonicalSizeBucketsBySymbol = selectorFamily<string[], string>({
  key: 'customerSecurities.canonSizeBucksBySym',
  get:
    (symbol: string) =>
    ({ get }) =>
      get(responseBySymbol(symbol))?.SizeBuckets?.map(({ Size }) => Size),
})

export const customerSecurities = {
  responseBySymbol,
  normalSizeBySymbol,
  canonicalSizeBucketsBySymbol,
}
