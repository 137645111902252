import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

export const DEFAULT_CURRENCY_PAIR: CurrencyPair = [
  { Symbol: 'BTC', Description: 'Bitcoin' },
  { Symbol: 'USD', Description: 'USD Coin' },
]

export const getDefaultStreamingTileSliver = (): StreamingTileLocalSliver => ({
  currencyPair: DEFAULT_CURRENCY_PAIR,
  amount: '',
  normalSize: '',
  sizeBuckets: [],
})
