import { customerSecurities } from './customerSecurities'
import { marketDataSnapshots } from './marketDataSnapshots'
import { mockPairs } from './mockPairs'
import { prices } from './prices'
import { pricingSimulation } from './pricingSimulation'
import { quoteReponses, quoteResponseState } from './quote'
import { socketMeta } from './socketMeta'
import { streamingTiles } from './streamingTiles'
import * as trade from './trade'
import { viewState } from './view'

const store = {
  customerSecurities,
  marketDataSnapshots,
  mockPairs,
  prices,
  pricingSimulation,
  quoteReponses,
  quoteResponseState,
  socketMeta,
  streamingTiles,
  trade,
  viewState,
}

export default store
